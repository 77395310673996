import { ref, watch, computed } from 'vue';
export default function useLocalStorage(key, defaultValue, options = {}) { const storageKey = computed(() => typeof key === 'string' ? key : key.value); const isExpired = updatedAt => { if (options.expireInSeconds === undefined)
    return false; return updatedAt + options.expireInSeconds * 1000 < Date.now(); }; const retrieveValue = currentKey => { const storedItem = localStorage.getItem(currentKey); if (!storedItem)
    return defaultValue; const parsedItem = JSON.parse(storedItem); const isLegacyFormat = parsedItem.value === undefined; if (isLegacyFormat || isExpired(parsedItem.updatedAt)) {
    localStorage.removeItem(currentKey);
    return defaultValue;
} return parsedItem.value; }; const itemRef = ref(retrieveValue(storageKey.value)); watch(storageKey, newKey => { itemRef.value = retrieveValue(newKey); }); watch(itemRef, newValue => { if (newValue === null) {
    localStorage.removeItem(storageKey.value);
    return;
} const storedValue = { value: newValue, updatedAt: Date.now() }; localStorage.setItem(storageKey.value, JSON.stringify(storedValue)); }, { deep: true }); return itemRef; }
